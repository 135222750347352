<template>
    <detalhe titulo="Status" :size="size">
        <status :status="atendimento?.statusDescr"></status>
    </detalhe>
    <detalhe titulo="Recebido em" :size="size">
        {{ $dateFormat(atendimento?.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Data do Atendimento" :size="size">
        {{ $dateFormat(atendimento?.dataAtendimento, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Cód. de Atendimento" :size="size">
        {{ atendimento?.codAtendimento }}
    </detalhe>
    <detalhe titulo="Unidade de Atendimento" :size="size">
        {{ atendimento?.unidadeAtendimento }}
    </detalhe>
    <detalhe titulo="CPF" :size="size">
        {{ atendimento?.cpf }} <status :status="atendimento?.cpfSasOk ? 'SAS OK' : 'SAS NOK'" v-if="mostrarStatusSas(atendimento?.cpf, atendimento?.status)"></status>
        <!-- <status status="CPF NÃO RECEBIDO" v-if="!atendimento?.cpf"></status> -->
    </detalhe>
    <detalhe titulo="Nome CPF" :size="size">
        {{ atendimento?.nomeCpf }}
    </detalhe>
    <detalhe titulo="CNPJ" :size="size">
        {{ atendimento?.cnpj }} <status :status="atendimento?.cnpjSasOk ? 'SAS OK' : 'SAS NOK'" v-if="mostrarStatusSas(atendimento?.cnpj, atendimento?.status)"></status>
        <status status="CNPJ NÃO RECEBIDO" v-if="!atendimento?.cnpj"></status>
    </detalhe>
    <detalhe titulo="Nome CNPJ" :size="size">
        {{ atendimento?.nomeCnpj }}
    </detalhe>
    <detalhe titulo="Descrição do Atendimento" :size="size">
        {{ atendimento?.descricaoAtendimento }}
    </detalhe>
    <detalhe titulo="Integrado em" :size="size" v-if="atendimento?.dataIntegracao">
        {{ $dateFormat(atendimento?.dataIntegracao, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Atualizado em" :size="size">
        {{ $dateFormat(atendimento?.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
</template>

<script>
import { atendimentoStatus } from './AtendimentoStatus';

export default {
    props: {
        atendimento: {
            type: Object,
        },
    },

    data() {
        return {
            size: 180,
        };
    },

    methods: {
        mostrarStatusSas(dado, status) {
            return dado && status != atendimentoStatus.RECEBIDO;
        },
    },
};
</script>
