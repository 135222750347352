export const atendimentoStatus = {
    RECEBIDO: 0,
    PRONTOPARAINTEGRACAO: 1,
    INDISPONIVELPARAINTEGRACAO: 2,
    INTEGRADO: 3,
    ERRONAINTEGRACAO: 4,
    INTEGRADOPF: 5,
    INTEGRADOPJ: 6,
    PRONTOPARAINTEGRACAOPF: 7,
    PRONTOPARAINTEGRACAOPJ: 8
};

export const atendimentoStatusLista = [
    { status: 0, statusDescr: 'RECEBIDO' },
    { status: 1, statusDescr: 'PRONTO PARA INTEGRAÇÃO' },
    { status: 2, statusDescr: 'INDISPONÍVEL PARA INTEGRAÇÃO' },
    { status: 3, statusDescr: 'INTEGRADO' },
    { status: 4, statusDescr: 'ERRO NA INTEGRAÇÃO' },
    { status: 5, statusDescr: 'INTEGRADO PF' },
    { status: 6, statusDescr: 'INTEGRADO PJ' },
    { status: 7, statusDescr: 'PRONTO PARA INTEGRAÇÃO PF' },
    { status: 8, statusDescr: 'PRONTO PARA INTEGRAÇÃO PJ' },
];