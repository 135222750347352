<template>
    <painel :titulo="`Expressos - Atendimento - ${atendimento?.codAtendimento}`" icone="pi pi-comments" :refreshFunction="obterAtendimento">
        <div class="mb-4">
            <TabView v-model:activeIndex="activeIndex">
                <TabPanel header="Dados Gerais">
                    <dados-gerais :atendimento="atendimento"></dados-gerais>
                </TabPanel>
                <TabPanel header="Atendimento Recebido">
                    <vue-json-pretty v-if="atendimento" :data="JSON.parse(atendimento?.dadosRecebidos)" :showDoubleQuotes="true"></vue-json-pretty>
                </TabPanel>
                <TabPanel header="Objeto Integração" v-if="atendimento && atendimento.integracaoObjeto">
                    <vue-json-pretty :data="JSON.parse(atendimento?.integracaoObjeto)" :showDoubleQuotes="true"></vue-json-pretty>
                </TabPanel>
                <TabPanel header="Retorno Integração" v-if="atendimento && atendimento.integracaoRetorno">
                    <vue-json-pretty :data="JSON.parse(atendimento?.integracaoRetorno)" :showDoubleQuotes="true"></vue-json-pretty>
                </TabPanel>
            </TabView>
        </div>
        <btn-voltar :route="{ name: 'Expressos_Atendimentos' }"></btn-voltar>
    </painel>
</template>

<script>
import AtendimentosService from './services';
import DadosGerais from './DadosGerais';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        DadosGerais,
        VueJsonPretty,
    },

    data() {
        return {
            atendimento: null,
            activeIndex: 0,
        };
    },

    methods: {
        obterAtendimento() {
            this.$store.dispatch('addRequest');
            AtendimentosService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.atendimento = response.data;
                } else {
                    this.atendimento = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizarAtendimento(atendimentoAtualizado) {
            this.atendimento = atendimentoAtualizado;
        },
    },

    computed: {
        // mostrarBtnIntegrar() {
        //     return this.activeIndex == 0 && this.$temAcessoView('RD-LEADS-01');
        // },
    },

    mounted() {
        this.obterAtendimento();
        if (this.$route.query.view == 'dados') {
            this.activeIndex = 1;
        }
    },
};
</script>
