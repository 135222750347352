import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPRESSOS_PORT}${process.env.VUE_APP_API_PATH}/expressos`;
const urlBase = `${api}/atendimentos`;

export default {
    obterTodos(filtro) {
        return axiosJwt.get(
            `${urlBase}?dataAtendimentoDe=${filtro.dataAtendimentoDe}&dataAtendimentoAte=${filtro.dataAtendimentoAte}`
        );
    },

    obterPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },

    obterUnidades() {
        return axiosJwt.get(`${urlBase}/unidades`);
    },

    exportar(atendimentos) {
        return axiosJwt.post(`${urlBase}/exportar`, atendimentos, {
            responseType: 'blob',
        });
    },

    integrar(atendimentos) {
        return axiosJwt.post(`${urlBase}/integrar`, atendimentos);
    },
};
